
.App {
  text-align: center;
}

.wk-loading-button{display: flex !important; align-items: center !important;}
.wk-loading-button span{font-size: 18px !important; margin-left: 5px;}
.wk-loading-button div{ padding: 0px !important;}
.wk-text-left{ text-align: right; }

/* -------------some basic styles*=-----------------------*/
.text-light {
  color: white !important;
}

.margin-22 {
  margin-top: 22px;

  @media (max-width: 768px) {
    margin-top: -10px;
    /* Potential issue */
  }
}

/* =========check when version change============== */
.cg-notification-2-6-0.cg-notification-toast {
  right: 2rem !important;
  top: 2rem !important;
  width: 22rem !important;
}

.bg-blue {
  background-color: rgb(0, 122, 195);
}

.right-align {
  text-align: right;
}

.margin {
  margin: 10px 0px 10px 0;
}

.mar-bottom {
  margin-bottom: 80px !important;
}

.marginBottom {
  margin-bottom: none !important;
}

.margin-top-bottom {
  margin: 5px 0px;
}

.width-30 {
  width: 30%;
}

.margin-top-14 {
  margin-top: -18px;
  margin-bottom: 5px;
}

.width-45 {
  width: 45%;
}



.width270 {
  width: 265px;
}

.tableAction {
  display: flex;
  align-items: center;
}

/*---------------------------------footer------------------------------*/
.footerContainer {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.padding10 {
  padding: 10px;
}

/* --------------------------------JournalCard-------------------------------- */
/* =========check when version change============== */
.JournalCard .cg-card-tile-2-6-0 {
  margin-bottom: 0px !important;
}


/* =========check when version change============== */
.JournalCard .cg-card-body-2-6-0 {
  padding: 1rem 1rem !important;
}

.card-sub-count {
  display: flex;
  justify-content: space-between;
}

/*--------------------------------scrollbar---------------------------------------*/


::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  height: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: hwb(0 44% 56%);
}

button:focus {
  outline: none !important;
  /* Remove the focus outline */
}

/*------------MemberUpdateModal -----------------------------*/
.MemberUpdateModal .cg-fullscreen-2-6-0 {
  width: 940px !important;
}

/*-----------------POWERBI-CONTAINER---------------------*/
.embed-container {
  height: 76vh;
}

.reportClass {
  height: 70vh;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




.wk-row {
  flex-wrap: wrap !important;
}




@media screen and (max-width: 767px) {
  .footerContainer {
    position: static;
    width: 100%;
    bottom: 0;
  }


  /*-----------------cards responsive------------*/
  .wk-grid-stage-workflow-container .wk-column-4,
  .wk-grid-stage-workflow-container .wk-column-3,
  .wk-grid-stage-workflow-container .wk-column-5,
  .wk-grid-stage-workflow-container .wk-column-6,
  .wk-grid-stage-workflow-container .wk-column-7 {
    flex-basis: calc(12 * (calc(8.3333333333% - 1rem * 11 / 12) + 1rem) - 1rem);
    max-width: inherit;
  }

}



/*===REFACTORING CSS===*/



.cg-navbar-style-classic{
  background: #fff;

}

.custom-error-message{
  margin-top: -10px;
  margin-bottom: 15px;
}

.wk-form-custom .cg-button-field-2-6-0 {
  width: 100%;
}

.wk-form-custom h1 {
  margin-bottom: 30px;
  text-align: center;
}

/*===Membercard CSS===*/
.wk-journalcard-size {
  padding: 5px;
  height: 140px;
}
.wk-journalcard-count {
  cursor: pointer;
}
.wk-journalcard-size span {
  text-transform: capitalize !important;
  letter-spacing: normal !important;
}
.wk-cards-toptitle {
    font-size: 13px;
    line-height: 10px;
}


/*===Membercard CSS END===*/

.wk-help-align {
  margin-top: 20px;
}

.wk-custom-memberlist {
  margin-bottom: 20px;
}
@media (max-width: 768px) {

  .wk-form-container {
    height:auto !important;
    flex-direction: column !important;
}
.wk-login-form{width: 100%;}
.wk-login-content-right {
  width: 100%;
  box-shadow: none !important;
  padding: 0px;
  margin-top: 20px;
}
.wk-notification{
  padding: 15px;
}

  .wk-form-custom {
    width: 100% !important;

  }

  .userlistdiv {

    max-height: calc(100vh - 450px);

    width: 100%;

  }

  .wk-custom-footer {
    position: static !important;
  }

  input {
    cursor: text;
  }
}

.suggestion-button{ width: 100%; background: #fff; border: none; text-align: left; display: block;}
.suggestion-button:hover{cursor: pointer;}

