@import '@wk/fundamentals/reset';
@import '@wk/fundamentals/grid/grid.scss';
@import '@wk/components/dist/table.min.css';
@import '@wk/fundamentals/helpers.scss';
@import '@wk/components/dist/tag.min.css';
@import '@wk/components-react16/dist/fonts';
@import '@wk/components/dist/notification.min.css';
@import '@wk/components-react16/dist/iconsvg.css';
@import '@wk/components-react16/dist/sr-only';
@import '@wk/components-react16/dist/banner';
@import '@wk/components-react16/dist/button-field';
@import '@wk/components-react16/dist/fishbowl';
@import '@wk/components-react16/dist/navbar';
@import '@wk/grid/grid.scss';
@import '@wk/components-react16/dist/tag';
@import '@wk/components-react16/dist/wk-footer';
@import '@wk/components-react16/dist/forms';
@import '@wk/components-react16/dist/card-container';
@import '@wk/components-react16/dist/button-group';
@import '@wk/components-react16/dist/dropdown.css';
@import '@wk/components-react16/dist/modal.css';
@import '@wk/components-react16/dist/pagination.css';
@import '@wk/components-react16/dist/pagination-bar.css';
@import '@wk/components-react16/dist/accordion.css';


/*===import sass files===*/

@import "./Styles/variables";
@import "./Styles/mixins";

/*===import other css files===*/

@import "./Components/Footer/footer";


body {
  background-color: $background-color;
  font-family: $body-font-family;
}


* {
  box-sizing: border-box;

}

.wk-divider {
  margin: $mb * 2 0px;
}

.wk-sub-title {
  margin: 2rem 0;
}

.wk-size-3 {
  margin-bottom: $mb ;
}
.th-toggle-icon{cursor: pointer;}

.user-list-section{

  width: 100%;

  position: relative;

}
.flex-btn-div{
  display: flex;
}
 .flex-btn-div div:last-child {
  margin-left: auto;
  
}


.userlistdiv {
  max-height: calc(100vh - 400px);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

  list-style: none;

  overflow-y: auto;

  padding: 0.5rem 1rem;

  display: none;

  margin-top: -16px;

  position: absolute;

  z-index: 100;

  width: 100%;

}

.userlistdiv li{display: block;}

.userlistdiv li:hover{cursor: pointer;}

.userlistdiv.active {
  display: block;
}


.wk-page-container-custom {
  padding: $padding *2;
  margin: 2rem 0;
  background: #fff;
  min-height: calc(100vh - 10.313rem);
 

}
.changePassword-form {
  padding: 30px;
  width: 30%;
  margin-left:35%;
  @include box-shadow;

  .cg-button-field-2-6-0 {
    display: block;

    .wk-login-button {
      margin-top: $mt - 0.5rem;
      width: 100%;
    }
  }

  h1 {
    margin-bottom: $mb ;
  }
}
.wk-login-form {
  padding: 30px;
  width: 100%;
  @include box-shadow;

  .cg-button-field-2-6-0 {
    display: block;

    .wk-login-button {
      margin-top: $mt - 0.5rem;
      width: 100%;
    }
  }

  h1 {
    margin-bottom: $mb ;
  }
}

.cg-field-password {
  margin-top: $mt * 2;
}

.wk-form-custom {
  padding: 30px;
  @include box-shadow;
}

.wk-form-container {
  height: 58vh;
  @include horizontal-list;
  
}
.wk-login-content-right{
  padding: 30px;
  
}
.login-contianer{
  display: flex;
  align-items: center;
}
.wk-grid-flex{
  display: flex;
  align-items: center;
}

.content {
  height: 50vh;
  @include vertical-list;

}

.content svg {
  height: 3rem !important;
  width: 3rem !important;
}

.content h1 {
  margin-bottom: 0;
}

.content h2 {
  margin-top: 0;
}

.table-button-np {
  padding: 0 !important;
}

.wk-pagination{margin-bottom: 50px;}