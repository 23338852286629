@mixin horizontal-list {
    display: flex;   
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    
}

@mixin vertical-list {
    display: flex;   
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

@mixin box-shadow {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0,0,0,.1);
    
}



    